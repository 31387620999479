<!-- @TODO THIS Component is lowkey broken and needs to be fixed -->
<template>
    <!-- <div class="pa-1 bg-primary rounded"> -->
        <!-- <div class="d-flex flex-wrap flex-md-nowrap" :style="smAndDown ? 'gap: 4px':''"> -->
        <div class="d-md-flex justify-md-end">
            <div class="overflow-auto">
                <component 
                    :is="breakScale ? 'v-slide-group' : 'div'" 
                    :class="[breakScale ? 'radio-slide-group' : 'radio-flex-group']" 
                    :multiple="multiple" 
                    v-model="selected" 
                    show-arrows>
                    <template 
                        v-for="item, index in items" 
                        :key="item[itemValue]">
                            <component :is="breakScale ? 'v-slide-group-item' : 'div'" :value="item[itemValue]">
                                <v-btn 
                                    :class="buttonClasses(index)"
                                    variant="flat"
                                    @click.stop="handleSelect(item)"
                                    class="px-1 survey-radio-buttons"
                                    height="40px"
                                    style="padding-right: 8px !important; margin-bottom: 2px"
                                    :style="buttonStyle(item, index)"                        
                                    min-width="auto">
                                    <!-- <v-icon v-if="Boolean(item[itemIcon])" :icon="'mdi-'+tempTranslateItem(item[itemIcon])" class="mr-1" size="x-large"></v-icon> -->
                                    <!-- <component v-else density="compact" :is="radioComponent" :false-icon="radioFalseIcon(item)"  :color="radioColor(item)" hide-details="true"></component> -->
                                    <!-- <v-radio v-else density="compact" :false-icon="radioFalseIcon(item)" 
                                    :true-icon="variant == 'checkbox' ? 'mdi-checkbox-blank-outline' : 'mdi-radiobox-blank'"
                                    :color="radioColor(item)"></v-radio> -->                                                                
                                    <v-icon :icon="getIcon(item)" :color="getIconColor(item)"></v-icon>                        
                                    <span class="text-none text-black font-weight-regular text-body-2" style="overflow-wrap: break-all;">
                                        {{ item[itemName] }}
                                    </span>
                                </v-btn>                
                            </component>
                        <!-- <v-divider vertical v-if="index < items.length-1"></v-divider> -->
                    </template>
                </component>
                <div class="text-red ml-2" style="font-size: 12px;" v-if="!hideDetails">
                    {{ errorMessages }}
                </div>
            </div>
        </div>
        <!-- </div> -->
    <!-- </div> -->
</template>

<script setup>
import {ref, watch, computed, toRaw} from 'vue';
import {useDisplay} from 'vuetify';

const {mdAndUp, smAndDown} = useDisplay();

const props = defineProps({
    items: {
        type: Array,
        default:  []
    },
    itemName: {
        type: String,
        default: 'name'
    },
    itemValue: {
        type: String,
        default: 'value'
    },
    itemColor: {
        type: String,
        default: 'color'
    },
    itemIcon: {
        type: String,
        default: 'icon'
    },
    modelValue: {
        default: ''
    },
    multiple: {
        type: Boolean,
        default: false
    },
    hideDetails: {
        type: Boolean,
        default: false
    },
    errorMessages: {
        type: String,
        default: ''
    },
    variant: {
        type: String,
        default: 'radio'
    }
});

const loadSelection = function(){
    if (props.multiple) {
        if (typeof props.modelValue === 'string' && props.modelValue !== '') {
            return JSON.parse(props.modelValue);
        }
        // @TODO hier wurde ein Fehler gefunden bruh
        else if (Array.isArray(props.modelValue)){
            return JSON.parse(JSON.stringify(props.modelValue)); // Diese Zeile tut sehr weh (ohne das JSON-Konstrukt wird die reaktivität von vue nicht getriggert, sodass items und farben nicht neugeladen werden)
        }
        return [];
    }
    return props.modelValue;
}

const selected = ref(loadSelection());

const emit = defineEmits(['update:modelValue']);
// const radioComponent = computed(function(){return 'v-'+props.variant});
const variant = computed(()=>{
    var noIcons = props.items.filter(item => !Boolean(item.icon));
    if (noIcons.length > 0) {
        return 'default';
    }
    return 'outlined';
});

// watch(selected, (newValue) => {
//     console.log('selected watcher triggered')
//     emit('update:modelValue', newValue);
// }, {deep: true});

const handleSelect = function(item){
    if (props.multiple) {
        var index = toRaw(selected.value).indexOf(item[props.itemValue])
        if (index == -1) {
            selected.value.push(item[props.itemValue])
        }
        else{
            selected.value.splice(index, 1);
        }
    }
    else{
        selected.value = item[props.itemValue];
    }    
    emit('update:modelValue', selected.value);
}

const buttonStyle = function(item, index){
    var style = '';

    if (variant.value == 'outlined') {
        if ((props.multiple ? selected.value.includes(item[props.itemValue]) : selected.value == item[props.itemValue]) && item[props.itemColor] != '#000000') {
            style += 'background-color: '+item[props.itemColor]+' !important; ';
        }

        if(breakScale.value){
            if (index != props.items.length-1) {
                style += 'border: 1px solid darkslategrey; border-right: none;'
            }
            else{
                style += 'border: 1px solid darkslategrey;'
            }
        }
        else{
            style += 'border: 1px solid darkslategrey;'
        }
    }
    
    return style;
}

const buttonClasses = function(index){
    // display.mdAndUp ? index == 0 ? 'rounded-s rounded-e-0' : index == items.length-1 ? 'rounded-e rounded-s-0' : 'rounded-0' : ''
    // console.log('aal',mdAndUp.value)
    var classes = '';
    if (breakScale.value) {
        if (index == 0) {
            classes+='rounded-s rounded-e-0 ';
        }
        else if (index == props.items.length-1) {
            classes+='rounded-e rounded-s-0 ';
        }
        else{
            classes+="rounded-0 "
        }
    }
    return classes;
}

// const radioColor = function(item){
//     if (props.variant == 'default') {
//         return item[props.itemColor].toString();
//     }
//     return 'darkslategrey';
// }

// @TODO this needs to be changed after scale create was updated
// const tempTranslateItem = function(item){
//     switch (item) {
//         case 'sentiment_satisfied':
//             return 'emoticon-excited-outline';            
//         case 'mood':
//             return 'emoticon-happy-outline';
//         case 'mood_bad':
//             return 'emoticon-sad-outline';
//         case 'sentiment_dissatisfied':
//             return 'emoticon-cry-outline';
//         case 'block':
//             return 'minus-circle-outline';
//         default:
//             break;
//     }
// }

// const radioFalseIcon = function(item){
//     if (props.multiple ? selected.value.includes(item[props.itemValue]) : selected.value == item[props.itemValue]) {
//         return props.variant == 'checkbox' ? 'mdi-checkbox-outline' : 'mdi-radiobox-marked';
//     }
//     return props.variant == 'checkbox' ? 'mdi-checkbox-blank-outline' : 'mdi-radiobox-blank';
// }

const getIcon = function(item){   
    var trueIcon;
    var falseIcon;

    if(item[props.itemIcon] != null){
        return 'mdi-'+item[props.itemIcon];
    }

    if (props.variant == 'checkbox') {
        trueIcon = 'mdi-checkbox-marked-outline';
        falseIcon = 'mdi-checkbox-blank-outline';
    }
    else{
        trueIcon = 'mdi-radiobox-marked';
        falseIcon = 'mdi-radiobox-blank';
    }

    if (props.multiple) {
        var index = toRaw(selected.value).indexOf(item[props.itemValue])
        if (index == -1) {
            return falseIcon;
        }
        else{
            return trueIcon;
        }
    }
    else{
        if (item[props.itemValue] == selected.value){
            return trueIcon;
        }
        else{
            return falseIcon;
        }        
    }
}

const getIconColor = function(item){
    if (item[props.itemColor] != null && variant.value != 'outlined') {
        return item[props.itemColor];
    }
    return 'darkslategrey';
}

const breakScale = computed(()=>{
    return mdAndUp.value && props.items.length <= 5;
})

watch(()=>props.modelValue, ()=>{
    selected.value = loadSelection();
}, {deep: true})
</script>

<style>
.v-btn--active.survey-radio-buttons > .v-btn__overlay{
    opacity: 0 !important;
}

.radio-slide-group > .v-slide-group__prev, .radio-slide-group > .v-slide-group__next{
    width: 24px;
    min-width: 24px;
    flex-basis: 24px;
}

.radio-flex-group{
    display: flex;
    flex-wrap: wrap;
    column-gap: 6px;
}

@media screen and (max-width: 400px){
    .radio-flex-group{
        flex-direction: column;
    }
}
</style>